import { SelectElementType } from './ui-components'
import {
  Bookies,
  ChannelDetailed,
  CountryModel,
  PriceBreakdown,
  SportEventType,
  UserGender,
  UserOnboardingStages,
  UserRoles,
  WaitlistContinentsEnum
} from '@app/graphql'

export interface UserAuthStateType {
  userAppName: string;
  countriesList: CountryModel[];
  userCountryId: string;
  userLanguageId: string;
  languagesList: {id:string,name:string,code:string}[];
  userBirthdayDate: Date;
  favouriteSports: Set<string>;
  favouriteBookies?: Set<string>;
  favouriteMarkets?: Set<string>;
  favouriteCompetitions: Map<string, Set<string>>;
  affiliateLinkName: string;
  inviteeTipsterLinkName: string;
  inviteLinkName: string;
  webpSupported: boolean;
  firstName: string;
  lastName: string;
  followLinkName: string;
  translatedCountry: any;
  userEmail?: string;
  install?: string;
}

export type CurrentCountry = Omit<CountryModel, 'continent'> & {continent: WaitlistContinentsEnum}
export enum ExtraUserRoles {
  Guest = 'GUEST'
}

export type AllUserRoles = UserRoles | ExtraUserRoles

export interface CurrentUserStateType {
  hasPendingSubscription: boolean;
  initiateOnboarding: boolean;
  userType: AllUserRoles;
  userEmail: string;
  username: string;
  id: string;
  name: string;
  surname: string;
  avatarUrl: string;
  profileBackgroundUrl: string;
  birthday: Date;
  countryId: string;
  bio: string;
  phone: string;
  authBySocial: boolean;
  incognitoMode: boolean;
  confirmedConditions: boolean;
  gender: UserGender;
  banned: boolean;
  muted: boolean;
  notedAboutMute: boolean;
  permAddPost: boolean;
  permEditPost: boolean;
  onboardingStage?: UserOnboardingStages;
  ownedChannels: Pick<ChannelDetailed, 'id' | 'channelName' | 'logo' | 'settings'>[],
  auth_type: 'telegram' | 'facebook' | 'google',
  readonly guestMode: boolean,
  readonly isTipster: boolean,
  readonly hasOnboarded: boolean,
  unitValue: number;
  unitShare: number;

}

export enum TipCreationStep {
  event = 'EVENT',
  bookie = 'BOOKIE',
  post = 'POST',
  chooseBookie = 'CHOOSE_BOOKIE',
  postTip = 'POST_TIP',
}

export interface Tip {
  tipTemporaryId: string;
  sportId: string;
  sportName: string;
  countryId: string;
  countryName: string;
  competitionId: string;
  competitionName: string;
  eventDate: Date;
  eventId: string;
  eventName: string;
  eventData: SportEventType;
  marketId: string;
  marketName: string;
  outcomeId: string;
  outcomeName: string;
  odd: number;
}

export interface TipCreationStateType extends Tip {
  creationStep: TipCreationStep;
  searchEvent: string;
  searchMode: boolean;
}

export interface TipsCreationListStateType {
  tipsList: Tip[];
  isVIP: boolean;
  tipsComment: string;
  withComments: boolean;
  tipAnalysis: string;
  bookieName: string;
  bookieId: string;
  isCustomOdds: boolean;
  tipStake: number;
  bookieList: Bookies[];
  includeInStat: boolean,
}

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: readonly string[];
  readonly userChoice: Promise<{
    readonly outcome: 'accepted' | 'dismissed';
    readonly platform: string;
  }>;
  prompt(): Promise<void>;
}

export interface AppSettingsStateType {
  favouriteSports: Set<string>;
  bookiesList: SelectElementType[];
  favouriteBookies: Set<string>;
  leaguesList: SelectElementType[];
  favouriteCompetitions: Set<string>;
  marketsList: SelectElementType[];
  favouriteMarkets: Set<string>;
}

export interface MatchListStateType {
  sportId: string;
  categoryId: string;
  competitionId: string;
  competitionName: string;
  eventsDate: Date;
  eventId: string;
  favouriteEvents: Set<string>;
}

export interface InboxStateType {
  expandedTipId: string;
  featureName: string;
  featureDescription: string;
  conversationId: string;
  conversationTitle: string;
  conversationImageSrc: string;
}

export interface PaymentStateType {
  paymentResult: string
  serviceTitle: string
  serviceSubtitle: string
  servicePrice: number
  serviceCurrency: string
  subscriptionId: string
  paymentTypeName: string
  paymentTypeId: string
  serviceDuration: number | string
  priceBreakdown: PriceBreakdown
  serviceCurrencySymbol: string
  channelName: string
}
