import { proxy } from 'valtio'

import { PaymentStateType } from '@app/types'

const initialState: PaymentStateType = {
  paymentResult: null,
  serviceTitle: null,
  serviceSubtitle: null,
  serviceCurrency: null,
  serviceCurrencySymbol: null,
  servicePrice: 0,
  subscriptionId: null,
  paymentTypeName: null,
  paymentTypeId: null,
  serviceDuration: null,
  priceBreakdown: null,
  channelName: null,
}

export const PaymentState = proxy<PaymentStateType>(initialState)

export const resetPaymentState = () => {
  Object.keys(initialState).forEach(key => {
    PaymentState[key] = initialState[key]
  })
}
